import axios from "axios";
import _ from "lodash";

export interface ResponseType {
  status?: number;
  message?: null | string;
  data?: object;
}


export const invoke = async (
  route: string,
  method: "get" | "post" | "put" | "delete" | "patch",
  data: any
): Promise<any> => {
  return axios({
    method,
    url:
      _.isUndefined(process.env.REACT_APP_ENV) ||
      _.isUndefined(process.env.REACT_APP_DEV_SERVER_URL) ||
      _.isUndefined(process.env.REACT_APP_PROD_SERVER_URL)
        ? `https://booking.parisflextransfer.com/${route}`
        : process.env.REACT_APP_ENV === "production"
        ? `${process.env.REACT_APP_PROD_SERVER_URL}${route}`
        : `${process.env.REACT_APP_DEV_SERVER_URL}${route}`,
    data,
  });
};
