import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import "./index.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { ReactComponent as Location } from "../../assets/icons/location.svg";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { getAllLocations } from "../../services/locations";
import _ from "lodash";
import { ReactComponent as Cross } from "../../assets/icons/cross-black.svg";
import { BookingType, createBooking } from "../../services/bookings";
import { AxiosResponse } from "axios";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";

export type EditTripData = {
  pickUpLocation: string | null;
  dropOffLocation: string | null;
  passengers: number | null;
  pickUpTime: any;
  pickUpDate: any;
  returnDropOffLocation: any;
  returnPassengers: any;
  returnPickUpDate: any;
  returnPickUpLocation: any;
  returnPickUpTime: any;
  passengerId: number;
};

interface EditTripModalType {
  isModalVisible: boolean;
  onClose: any;
  editData: EditTripData;
  bookingDetails: BookingType;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingType>>;
}

export interface formDataType {
  dropOffLocation: any;
  pickUpLocation: any;
  passengers: number | null;
  pickUpTime: any;
  pickUpDate: any;
  returnPickUpLocation: any;
  returnDropOffLocation: any;
  returnPickUpTime: any;
  returnPickUpDate: any;
  returnPassengers: number | null;
}

const convertTripTimeToTimeObject = (time: string) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const dateTimeString = `${currentDate}T${time}:00`;
  const momentDate = moment(dateTimeString);

  if (!momentDate.isValid()) {
    return moment().format("YYYY-MM-DDTHH:mm:ss");
  }

  return momentDate.format("YYYY-MM-DDTHH:mm:ss");
};

const EditTripModal: React.FC<EditTripModalType> = (props) => {
  const [pickUpLocations, setPickupLocations] = useState<any[]>([]);
  const [dropOffLocations, setDropoffLocations] = useState<any[]>([]);
  const [formData, setFormData] = useState<formDataType>({
    dropOffLocation: props.editData.dropOffLocation,
    passengers: props.editData.passengers,
    pickUpDate: props.editData.pickUpDate,
    pickUpLocation: props.editData.pickUpLocation,
    pickUpTime: convertTripTimeToTimeObject(props.editData.pickUpTime),
    returnPickUpLocation: props.editData.returnPickUpLocation,
    returnDropOffLocation: props.editData.returnDropOffLocation,
    returnPickUpTime: convertTripTimeToTimeObject(props.editData.returnPickUpTime),
    returnPickUpDate: props.editData.returnPickUpDate,
    returnPassengers: props.editData.returnPassengers,
  });

  useEffect(() => {
    getAllLocations().then((response) => {
      if (!_.isEmpty(response) || !_.isEmpty(response.data.data)) {
        let locations: any[] = response.data.data;
        let pickUpLocations: any[] = locations.map((location) => {
          return {
            value: location.pickUpLocation,
            label: location.pickUpLocation,
          };
        });
        let dropOffLocations: any[] = locations.map((location) => {
          return {
            value: location.dropOffLocation,
            label: location.dropOffLocation,
          };
        });
        setPickupLocations(pickUpLocations);
        setDropoffLocations(dropOffLocations);
      }
    });
  }, []);

  useEffect(() => {
    try {
      const formattedPickUpDate = moment(formData.pickUpDate, "YYYY-MM-[W]E").format(
        "YYYY-MM-DD"
      );
      const formattedPickUpTime = moment(formData.pickUpTime).format("HH:mm");
      const formattedReturnPickUpDate = moment(formData.returnPickUpDate, "YYYY-MM-[W]E").format(
        "YYYY-MM-DD"
      );
      const formattedReturnPickUpTime = moment(formData.returnPickUpTime).format("HH:mm");

      props.setBookingDetails({
        ...props.bookingDetails,
        dropOffLocation: formData.dropOffLocation,
        pickUpDate: formattedPickUpDate,
        pickUpTime: formattedPickUpTime,
        pickUpLocation: formData.pickUpLocation,
        returnPickUpLocation: formData.returnPickUpLocation,
        returnDropOffLocation: formData.returnDropOffLocation,
        returnPickUpDate: formattedReturnPickUpDate,
        returnPickUpTime: formattedReturnPickUpTime,
        returnPassengers: formData.returnPassengers,
        passengerId: props.editData.passengerId,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } catch (e: any) {
      console.log(e);
    }
  }, [formData]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!_.isEmpty(props.bookingDetails) || !_.isUndefined(props.bookingDetails)) {
      createBooking(props.bookingDetails)
        .then((response: AxiosResponse) => {
          const restrcutredResponse: any = response.data;
          toast.success(restrcutredResponse.message, {
            position: "bottom-right",
          });
        })
        .catch((error: any) => {
          const response: any = error.response.data;
          toast.error(response.message, { position: "bottom-right" });
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        open={props.isModalVisible}
        onClose={props.onClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box className="edit-modal-styles" component="form" onSubmit={onSubmit}>
          <ToastContainer />
          <Grid container spacing={2}>
            <Grid xs={12}>
              <div className="close-icon">
                <Cross height={15} width={15} onClick={props.onClose} />
              </div>
            </Grid>

            {/* Outward Journey */}
            <Grid item xs={12}>
              <h3>Outward Journey</h3>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <InputLabel>Pickup Location</InputLabel>
                <div className="location-container">
                  <Location />
                  <Autocomplete
                    disablePortal
                    className="edit-trip-select"
                    options={pickUpLocations}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, value) =>
                      setFormData({ ...formData, pickUpLocation: value?.value || "" })
                    }
                    value={formData.pickUpLocation}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel>Dropoff Location</InputLabel>
                <div className="location-container">
                  <Location />
                  <Autocomplete
                    disablePortal
                    className="edit-trip-select"
                    options={dropOffLocations}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, value) =>
                      setFormData({ ...formData, dropOffLocation: value?.value || "" })
                    }
                    value={formData.dropOffLocation}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel>Pickup Time</InputLabel>
                <Clock />
                <DateTimePicker
                  onChange={(value) => setFormData({ ...formData, pickUpTime: value })}
                  value={formData.pickUpTime}
                  format="HH:mm"
                  calendarIcon={null}
                  clearIcon={null}
                  className="date-picker"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel>Pickup Date</InputLabel>
                <Calendar />
                <DateTimePicker
                  onChange={(value) => setFormData({ ...formData, pickUpDate: value })}
                  value={formData.pickUpDate}
                  format="y-MM-dd"
                  disableClock
                  calendarIcon={null}
                  minDate={new Date()}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel>Passengers</InputLabel>
                <TextField
                  type="number"
                  onChange={(event) =>
                    setFormData({ ...formData, passengers: +event.target.value })
                  }
                  value={formData.passengers || ""}
                />
              </Grid>
            </Grid>

            {/* Return Journey */}
            <Grid item xs={12}>
              <h3>Return Journey</h3>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <InputLabel>Return Pickup Location</InputLabel>
                <div className="location-container">
                  <Location />
                  <Autocomplete
                    disablePortal
                    className="edit-trip-select"
                    options={pickUpLocations}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, value) =>
                      setFormData({ ...formData, returnPickUpLocation: value?.value || "" })
                    }
                    value={formData.returnPickUpLocation}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel>Return Dropoff Location</InputLabel>
                <div className="location-container">
                  <Location />
                  <Autocomplete
                    disablePortal
                    className="edit-trip-select"
                    options={dropOffLocations}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, value) =>
                      setFormData({ ...formData, returnDropOffLocation: value?.value || "" })
                    }
                    value={formData.returnDropOffLocation}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel>Return Pickup Time</InputLabel>
                <Clock />
                <DateTimePicker
                  onChange={(value) => setFormData({ ...formData, returnPickUpTime: value })}
                  value={formData.returnPickUpTime}
                  format="HH:mm"
                  calendarIcon={null}
                  clearIcon={null}
                  className="date-picker"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel>Return Pickup Date</InputLabel>
                <Calendar />
                <DateTimePicker
                  onChange={(value) => setFormData({ ...formData, returnPickUpDate: value })}
                  value={formData.returnPickUpDate}
                  format="y-MM-dd"
                  disableClock
                  calendarIcon={null}
                  minDate={new Date()}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel>Return Passengers</InputLabel>
                <TextField
                  type="number"
                  onChange={(event) =>
                    setFormData({ ...formData, returnPassengers: +event.target.value })
                  }
                  value={formData.returnPassengers || ""}
                />
              </Grid>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} md={4}>
              <Button className="save-button-styles" type="submit">
                Save Trip
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default EditTripModal;
