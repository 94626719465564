import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as Travellers } from "../../assets/icons/healthicons_travel-alt-outline.svg";
import { ReactComponent as Repeat } from "../../assets/icons/repeat.svg";
import { ReactComponent as OneWay } from "../../assets/icons/one-way.svg";
import { ReactComponent as StepA } from "../../assets/icons/step-a.svg";
import { ReactComponent as StepB } from "../../assets/icons/step-b.svg";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import "./index.css";
import EditTripModal from "../edit-trp-modal";
import AddReturnModal from "../add-return-modal";
import { BookingType } from "../../services/bookings";
import _ from "lodash";
import StepIconWrapper from "../step-icon-wrapper";

const YourTransfer: React.FC<{
  activeStep: number;
  bookingDetails: BookingType;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingType>>;
}> = (props) => {
  const [editModal, setEditModal] = useState(false);
  const [addReturn, setAddReturn] = useState(false);

  const handleClose = () => {
    setEditModal(false);
    setAddReturn(false);
  };
  const handleClick = () => {
    window.location.href = "https://parisflextransfer.com/";
  };
  return (
    <>
      <Card
        className="main-card-style"
        sx={{
          boxShadow: "0px 4px 70px rgba(62, 62, 185, 0.08)",
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            className="header-style"
            component="div"
          >
            <Travellers /> Your Transfer
          </Typography>


          <Divider variant="middle" />
          <p className="p-text-style">Outward journey</p>
          <div>
            <Box className="stepper-styles">
              <Stepper orientation="vertical" className="step-text-styles">
                <Step key={1}>
                  <StepLabel StepIconComponent={(props) => <StepIconWrapper {...props} Icon={StepA}/>}>
                    {_.isNull(props.bookingDetails.pickUpLocation)
                      ? "None"
                      : props.bookingDetails.pickUpLocation}
                  </StepLabel>
                </Step>
                <Step key={2}>
                  <StepLabel StepIconComponent={(props) => <StepIconWrapper {...props} Icon={StepB}/>}>
                    {_.isNull(props.bookingDetails.dropOffLocation)
                      ? "None"
                      : props.bookingDetails.dropOffLocation}
                  </StepLabel>
                </Step>
              </Stepper>
              <Stepper orientation="vertical">
                <Step key={3}>
                  <StepLabel StepIconComponent={(props) => <StepIconWrapper {...props} Icon={Calendar}/>}>
                    {props.bookingDetails.pickUpDate}
                  </StepLabel>
                </Step>
              </Stepper>
              <Stepper orientation="vertical">
                <Step key={4}>
                  <StepLabel StepIconComponent={(props) => <StepIconWrapper {...props} Icon={Clock}/>}>{props.bookingDetails.pickUpTime}</StepLabel>
                </Step>
              </Stepper>
            </Box>
          </div>


          {props.bookingDetails.tripType == 'one_way' ? 
            <>
              <Divider variant="middle" />
              <p className="p-text-style">Book smart ! Add a return Journey</p>

              <Grid container className="button-container">
                <Button className="box-styles onewayy">
                  <OneWay /> <p className="text-style">{props.bookingDetails.tripType}</p>
                </Button>
                <Button className="button-styles" onClick={handleClick}>
                  ADD RETURN <Repeat className="svg-icon" />
                </Button>
              </Grid>
            </> 
          : <>
              <Divider variant="middle" />
              <p className="p-text-style">Return journey</p>
              <div>
                <Box className="stepper-styles">
                  <Stepper orientation="vertical" className="step-text-styles">
                    <Step key={1}>
                      <StepLabel StepIconComponent={(props) => <StepIconWrapper {...props} Icon={StepA}/>}>
                        {_.isNull(props.bookingDetails.returnPickUpLocation)
                          ? "None"
                          : props.bookingDetails.returnPickUpLocation}
                      </StepLabel>
                    </Step>
                    <Step key={2}>
                      <StepLabel StepIconComponent={(props) => <StepIconWrapper {...props} Icon={StepB}/>}>
                        {_.isNull(props.bookingDetails.returnDropOffLocation)
                          ? "None"
                          : props.bookingDetails.returnDropOffLocation}
                      </StepLabel>
                    </Step>
                  </Stepper>
                  <Stepper orientation="vertical">
                    <Step key={3}>
                      <StepLabel StepIconComponent={(props) => <StepIconWrapper {...props} Icon={Calendar}/>}>
                        {props.bookingDetails.returnPickUpDate}
                      </StepLabel>
                    </Step>
                  </Stepper>
                  <Stepper orientation="vertical">
                    <Step key={4}>
                      <StepLabel StepIconComponent={(props) => <StepIconWrapper {...props} Icon={Clock}/>}>{props.bookingDetails.returnPickUpTime}</StepLabel>
                    </Step>
                  </Stepper>
                </Box>
              </div>
            </>
          }

          <Grid container className="edit-trip">
            {props.activeStep === 2 ? (
              <>
                <Typography variant="h6" className="change-mind-styles">
                  Change Mind?
                </Typography>
                <Button
                  className="edit-button"
                  onClick={() => setEditModal(true)}
                >
                  Edit Trip
                </Button>
              </>
            ) : (
              <></>
            )}
          </Grid>

        </CardContent>
      </Card>

      <EditTripModal
        editData={{
          dropOffLocation: props.bookingDetails.dropOffLocation,
          passengers: Number(props.bookingDetails.passengers),
          pickUpDate: props.bookingDetails.pickUpDate,
          pickUpLocation: props.bookingDetails.pickUpLocation,
          pickUpTime: props.bookingDetails.pickUpTime,
          returnDropOffLocation: props.bookingDetails.returnDropOffLocation,
          returnPassengers: Number(props.bookingDetails.returnPassengers),
          returnPickUpDate: props.bookingDetails.returnPickUpDate,
          returnPickUpLocation: props.bookingDetails.returnPickUpLocation,
          returnPickUpTime: props.bookingDetails.returnPickUpTime,
          passengerId: props.bookingDetails.passengerId,
        }}
        isModalVisible={editModal}
        onClose={handleClose}
        bookingDetails={props.bookingDetails}
        setBookingDetails={props.setBookingDetails}
      />

      <AddReturnModal isModalVisible={addReturn} onClose={handleClose} />
    </>
  );
};
export default YourTransfer;
