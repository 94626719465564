import React from 'react';
import { StepIconProps } from '@mui/material';

interface IconProps extends StepIconProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
}

const StepIconWrapper: React.FC<IconProps> = ({ Icon, active, completed, error, className }) => {
  return (
    <Icon
      className={className}
      style={{
        color: active ? 'blue' : completed ? 'green' : error ? 'red' : 'gray',
      }}
    />
  );
};

export default StepIconWrapper;
