import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
  Paper,
  CircularProgress,
  IconButton,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import NavigationBar from "../navigation-bar";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  getAllBookingsPassengersAndPassengerExtras,
  changeBookingStatus,
} from "../../../services/bookings";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { AxiosResponse } from "axios";
import { ToastContainer, toast } from "react-toastify";
import { deleteBooking, getAllBookings } from "../../../services/bookings";
import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import "./bookingSummary.css";
import { useSearchParams } from "react-router-dom";

const BookingsPassengrsSummary: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<any[]>([]);
  const [searchParams] = useSearchParams();
  const [date, setDate] = useState<string>(searchParams.get('date') ?? '')

  const fetchData = () => {
    setLoading(true);

    getAllBookingsPassengersAndPassengerExtras(date).then((response) => {
      const tableData = response.data.data;
      const sortedData = [...tableData].sort((a, b) => {
        const numA = parseInt(a.bookingRefId.slice(2), 10);
        const numB = parseInt(b.bookingRefId.slice(2), 10);
        return numB - numA;
      });
    
      if (!_.isEmpty(sortedData)) {
        setTableData(sortedData);
      } else {
        setTableData([])
      }

      setLoading(false);
    });
  }

  useEffect(() => {
    fetchData();
  }, [date]);

  const removeFilters = () => {
    setDate('');
  }

  const handleTripStatusChange = (bookingId: number) => {
    changeBookingStatus(bookingId)
      .then((response: AxiosResponse) => {
        const restrcutredResponse: any = response.data;
        toast.success(restrcutredResponse.message, {
          position: "bottom-right",
        });
      })
      .catch((error: any) => {
        const response: any = error.response.data;
        toast.error(response.message, { position: "bottom-right" });
      });
  };

  const handleDeleteBooking = (id: any) => {
    deleteBooking(id)
      .then((response: AxiosResponse) => {
        const restrcutredResponse: any = response.data;
        toast.success(restrcutredResponse.message, {
          position: "bottom-right",
        });
      })
      .catch((error: any) => {
        const response: any = error.response.data;
        toast.error(response.message, { position: "bottom-right" });
      });
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <ToastContainer />
      <NavigationBar />
      <Box sx={{ maxWidth: "100%", margin: "2% 8%" }}>
        <Box display={"flex"} flexDirection={'row'} sx={{width: "100%"}} marginBottom={2}>
          <Typography variant="h5" style={{flexGrow: 1}}>
            Booking / Passengers Summary
          </Typography>
          {date ? <>
            <Typography variant="caption" marginY={"auto"} marginRight={2}>
              Showing bookings on {date}
            </Typography>
            <button onClick={removeFilters}>Remove Filters</button>
          </> : null}
        </Box>

        <TableContainer component={Paper} sx={{ maxHeight: '75vh'}}>
          {loading ? (
            <CircularProgress color="success" />
          ) : _.isEmpty(tableData) ? (
            <Typography>No Booking Found</Typography>
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="table-header">Trip Type</TableCell>
                  <TableCell className="table-header">Name</TableCell>
                  <TableCell className="table-header">Last Name</TableCell>
                  <TableCell className="table-header">Phone</TableCell>
                  <TableCell className="table-header">Email</TableCell>

                  <TableCell className="table-header">Passenger Count</TableCell>
                  <TableCell className="table-header">Travel Number</TableCell>
                  <TableCell className="table-header">Travel From</TableCell>
                  <TableCell className="table-header">Pickup Date</TableCell>
                  <TableCell className="table-header">Pickup Time</TableCell>
                  <TableCell className="table-header">Pickup Location</TableCell>
                  <TableCell className="table-header">DropOff Location</TableCell>
                  <TableCell className="table-header">Luggage Pieces</TableCell>
                  <TableCell className="table-header">Pickup Landmark</TableCell>

                  <TableCell className="table-header">Return Passenger Count</TableCell>
                  <TableCell className="table-header">Return Travel Number</TableCell>
                  <TableCell className="table-header">Return Travel To</TableCell>
                  <TableCell className="table-header">Return Pickup Date</TableCell>
                  <TableCell className="table-header">Return Pickup Time</TableCell>
                  <TableCell className="table-header">Return Pickup Location</TableCell>
                  <TableCell className="table-header">Return DropOff Location</TableCell>
                  <TableCell className="table-header">Return Luggage Pieces</TableCell>
                  {/*<TableCell className="table-header">Return Pickup Landmark</TableCell>*/}

                  <TableCell className="table-header">Booking Ref Id</TableCell>
                  <TableCell className="table-header">Extras Description</TableCell>
                  <TableCell className="table-header">Child Seats</TableCell>
                  <TableCell className="table-header">Booster Seats</TableCell>
                  <TableCell className="table-header">Trip Status</TableCell>
                  <TableCell className="table-header">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => {
                  console.log(row);
                  return (
                    <TableRow key={row.id}>
                      <TableCell>{row.tripType.replace('_', ' ').toUpperCase()}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.lastName}</TableCell>
                      <TableCell>{row.phone}</TableCell>
                      <TableCell>{row.email}</TableCell>

                      <TableCell>{row.passengerCount}</TableCell>
                      <TableCell>{row.travelNumber}</TableCell>
                      <TableCell>{row.travelFrom}</TableCell>
                      <TableCell>{row.pickUpDate}</TableCell>
                      <TableCell>{row.pickUpTime}</TableCell>
                      <TableCell>{row.pickUpLocation}</TableCell>
                      <TableCell>{row.dropOffLocation}</TableCell>
                      <TableCell>{row.luggagePieces}</TableCell>
                      <TableCell>{row.pickUpLandMark}</TableCell>

                      <TableCell>{row.tripType == 'round_trip' ? row.returnPassengerCount : 'N/A'}</TableCell>
                      <TableCell>{row.tripType == 'round_trip' ? row.returnTravelNumber : 'N/A'}</TableCell>
                      <TableCell>{row.tripType == 'round_trip' ? row.returnTravelTo : 'N/A'}</TableCell>
                      <TableCell>{row.tripType == 'round_trip' ? row.returnPickUpDate : 'N/A'}</TableCell>
                      <TableCell>{row.tripType == 'round_trip' ? row.returnPickUpTime : 'N/A'}</TableCell>
                      <TableCell>{row.tripType == 'round_trip' ? row.returnPickUpLocation : 'N/A'}</TableCell>
                      <TableCell>{row.tripType == 'round_trip' ? row.returnDropOffLocation : 'N/A'}</TableCell>
                      <TableCell>{row.tripType == 'round_trip' ? row.returnLuggagePieces : 'N/A'}</TableCell>
                      {/*<TableCell>{row.returnPickUpLandMark}</TableCell>*/}

                      <TableCell>{row.bookingRefId}</TableCell>
                      <TableCell>{row.extrasDescription}</TableCell>
                      <TableCell>{row.childSeats}</TableCell>
                      <TableCell>{row.boosterSeats}</TableCell>
                      <TableCell>{row.tripStatus}</TableCell>

                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                          <div onClick={() => handleTripStatusChange(row.id)}>
                            <IconButton color="primary" aria-label="add to shopping cart">
                              <AutorenewIcon />
                            </IconButton>
                          </div>
                          <Delete onClick={() => handleDeleteBooking(row.id)} />
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>

      </Box>
    </div>
  );
};

export default BookingsPassengrsSummary;
