import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ReactComponent as Details } from "../../assets/icons/Calendar_New.svg";
import { ReactComponent as Extras } from "../../assets/icons/Extras_Active.svg";
import { ReactComponent as ExtrasWhite } from "../../assets/icons/Extras_New.svg";
import { ReactComponent as ConfirmWhite } from "../../assets/icons/Done.svg";
import { ReactComponent as Confirm } from "../../assets/icons/Done_Active.svg";
import "./index.css";
import { Grid } from "@mui/material";
import StepIconWrapper from "../step-icon-wrapper";
const ProgressStepper: React.FC<{ activeStep: number }> = (props: any) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      style={{
        background: "#F5F6FF",
        textAlign: "center",
        marginBottom: "2%",
      }}
      className="step-class-back"
    >
      <Stepper
        activeStep={props.activeStep}
        variant="outlined"
        className="stepper-class-style"
      >
        <Step key={1}>
          <StepLabel StepIconComponent={(props) => <StepIconWrapper {...props} Icon={Details} />}>Details</StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel
            StepIconComponent={(stepIconProps) => <StepIconWrapper {...stepIconProps} Icon={props.activeStep > 0 ? Extras : ExtrasWhite} />}
          >
            Extras
          </StepLabel>
        </Step>
        <Step key={3}>
          <StepLabel
            StepIconComponent={(stepIconProps) => <StepIconWrapper {...stepIconProps} Icon={props.activeStep > 1 ? Confirm : ConfirmWhite} />}
          >
            Confirm
          </StepLabel>
        </Step>
      </Stepper>
    </Grid>
  );
};

export default ProgressStepper;
