import NavigationBar from "./navigation-bar";
import { DateCalendar, LocalizationProvider, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Badge, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BookingType, getAllBookingsPassengersAndPassengerExtras } from "../../services/bookings";

dayjs.extend(utc);
dayjs.extend(timezone);

function ServerDay(props: PickersDayProps<Dayjs> & { highlightedDays?: any }) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const date = props.day.format('YYYY-MM-DD');
  const count = highlightedDays[date];
  const isSelected = !props.outsideCurrentMonth && count && count >= 1;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={ isSelected ?
        <Typography
          fontSize={8}
          textAlign={'center'}
          color={'white'}
          sx={{ backgroundColor: 'red', width: 12, height: 12, borderRadius: 4 }}
        >
        {count}
        </Typography>
      : null}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day}/>
    </Badge>
  );
}

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [highlightedDays, setHighlightedDays] = useState<any>({});

  const fetchDates = () => {
    getAllBookingsPassengersAndPassengerExtras('').then((response) => {
      const tableData: BookingType[] = response.data.data;

      const counts = tableData.reduce<Record<string, number>>((acc, { pickUpDate, returnPickUpDate }) => {
        acc[pickUpDate] = (acc[pickUpDate] || 0) + 1;
        if (returnPickUpDate) {
          acc[returnPickUpDate] = (acc[returnPickUpDate] || 0) + 1;
        }
        return acc;
      }, {});

      setHighlightedDays(counts);
    });
  }

  useEffect(() => {
    fetchDates();
  }, [])

  const handleDateSelected = (date: any) => {
    const formattedDate = date.format('YYYY-MM-DD');
    navigate(`/admin/booking-summary/?date=${formattedDate}`)
  }

  return (
    <div>
      <NavigationBar />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar 
          onChange={val => handleDateSelected(val)}
          slots={{
            day: ServerDay
          }}
          slotProps={{
            day: {
              highlightedDays,
            } as any,
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default AdminDashboard;
