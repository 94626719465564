import { invoke } from "../utls/api-adapter";

export type BookingType = {
  pickUpLocation: string;
  dropOffLocation: string;
  pickUpDate: string;
  pickUpTime: string;
  passengers: number;
  luggagePieces: number;
  price: string;
  pickUpLandMark: string;
  rateId: number;
  returnPickUpLocation: string | null;
  returnDropOffLocation: string | null;
  returnPickUpDate: string | null;
  returnPickUpTime: string | null;
  returnPassengers: number | null;
  returnLuggagePieces: number | null;
  returnPrice: string | null;
  returnPickUpLandMark: string | null;
  tripType: "one_way" | "round_trip" | "none";
  passengerId: number;
  returnRateId: number;
};

export async function getAllBookings() {
  return invoke("api/bookings/", "get", {});
}

export async function getAllBookingsPassengersAndPassengerExtras(date?: string) {
  return invoke(`api/bookings/get-bookings-and-passengers?date=${date}`, "get", {});
}

export async function createBooking(booking: BookingType) {
  return invoke("api/bookings/", "post", booking);
}

export async function changeBookingStatus(id: number) {
  return invoke(`api/bookings/change-trip-status/${id}`, "get", {});
}

export async function getBookingRef() {
  return invoke(`api/bookings/get-generated-booking-ref/`, "get", {});
}

export async function deleteBooking(id: number) {
  return invoke(`api/bookings/${id}`, "delete", {});
}
